.certificate {
	height: 200px;
	margin-bottom: 10%;
}

h5 {
	font-size: 1.2em;
}

h6: {
	font-size: 1.1em;
}

.card-title {
	font-size: 1.6em;
	padding: 5px;
}

.card-subtitle {
	padding: 5px;
}

.card-text {
	padding: 5px;
}