.container {
  overflow: hidden;
}


.filterDiv {
  float: left;
  color: #ffffff;
  width: 100px;
  text-align: center;
  margin: 20px;
  padding: auto;

  align-content: center;
  vertical-align: middle;
  background-color: #f1f1f1;

}

/* The "show" class is added to the filtered elements */
.hidden {
  display: none;
}

/* Style the buttons */
.btn.filter {
  border: none;
  outline: none;
  padding: 12px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
}

/* Add a light grey background on mouse-over */
.btn.filter :hover {
  background-color: #ddd;
}

/* Add a dark background to the active button */
.btn.filter.active {
  background-color: #666;
  color: white;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {

    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    margin: 5px;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {

    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    margin: 5px;

}

.btn-group :hover {
    background-color: #ddd;
}
