.form-item {
	background-color: #f3f3f3;
	width: 100%;
	border-style: none;
}

.form-submit {
	padding-top: 5px;
}

textarea {
	height: 200px;
}

.row {
	margin-top: 0;
	margin-bottom: 0;
}