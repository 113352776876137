li {
  list-style: none;
}

.brd-btm {
  border-bottom: solid;
  border-bottom-color: var(--secondary-color);  
  border-bottom-width: 1px;
  padding-bottom: 20px;
  padding-top: 20px
}

.bottom {
  padding-bottom: 20px;
  padding-top: 20px
}


.pie {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #eee;
  background-image: linear-gradient(to right, transparent 50%, #23272b 0);
  position: relative;
  display: inline-block;
  margin: 10px;
  font-size: 10pt;
}

.pie::before {
  content: "";
  display: block;
  margin-left: 50%;
  height: 100%;
  border-radius: 0 100% 100% 0 / 50%;
  background-color: inherit;
  transform-origin: left;
}

.pie::after {
  content: attr(data-value)"%";
  position: absolute;
  width: 90%;
  height: 90%;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font: 100 15px/71px Poppins;
}

@for $i from 0 through 100 {
  .pie[data-value="#{$i}"]:before {
    transform: rotate(#{$i/100}turn);
  }
}

@for $i from 51 through 100 {
  .pie[data-value="#{$i}"]:before {
    background-color: var(--main-bg-color);
    transform: rotate(#{$i/100 - 0.5}turn);
  }
}

.progress {
	height: 0.3rem;
}

.progress-title {
	font-size: 0.8em;
	margin-top: 5pt;
}

.progress-item {
  padding-bottom: 10pt;
}

.lng-bullets {
  padding: 10pt;
}
