:root {
  --main-bg-color: #343a40;
  --secondary-color: #999;
}

mark, .progress-bullets .bullet.fill, .tabs .tabs-menu li.active a, .tabs .tabs-menu li a:hover, .togglebox-header::before, .accordion-header::before, .education::before, .education-date, #crt-main-nav .sub-menu li > a:hover, #crt-main-nav .sub-menu .current > a, #crt-main-nav-sm .sub-menu::before {

    background-color: var(--secondary-color);

}



.content-white {
    padding-top: 5%;
}

.education {
    position: relative;
}

.education:before {
    top: 10px;
    left: 0;
    bottom: 0;
    width: 1px;
    content: '';
    opacity: 0.5;
    position: absolute;
}

.education-box {
    position: relative;
    padding-left: 20px;
    margin-bottom: 45px;
}

.education-box p {
    font-size: 0.875em;
}

.education-box:before {
    content: "";
    top: 9px;
    left: -2px;
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    border-color: black;
    border-style: solid;
}

.education-box h3 {
    letter-spacing: 0;
    font-size: 0.938em;
    margin-top: 15px;
    margin-bottom: 10px;
}

.education-box:last-child:after {
    top: 14px;
    left: 0;
    bottom: 0;
    width: 1px;
    content: '';
    position: absolute;
}

.education-date {
    left: -20px;
    width: 117px;
    letter-spacing: 0;
    line-height: 1.1;
    padding: 6px 6px;
    margin-left: 15px;
    font-size: 0.625em;
    position: relative;
    display: inline-block;
}

.education-date span {
    overflow: hidden;
    position: relative;
    display: block;
    white-space: nowrap;
}

.education-date:before {
    content: "";
    top: 0;
    right: 100%;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 11px solid transparent !important;
    border-bottom: 11px solid transparent !important;
    border-left: 0px solid transparent !important;
    border-right: 7px solid transparent;
}

.education-logo {
    margin-bottom: 5px;
}

.education-company {
    line-height: 1.1;
    letter-spacing: 0;
    font-size: 0.625em;
    margin-bottom: 15px;
    display: inline-block;
}

@media (min-width: 768px) {
    .education {
        margin-left: 180px;
    }

    .education:before {
        top: 10px;
    }

    .education-box {
        padding-right: 20px;
    }

    .education-box h3 {
        font-size: 20px;
        margin-bottom: 22px;
        margin-top: 0;
    }

    .education-box:before {
        top: 10px;
    }

    .education-date {
        margin-left: 0;
        margin-right: 22px;
        padding: 8px 6px;
        font-size: 0.875em;
        text-align: center;
        position: absolute;
        width: 160px;
        right: 100%;
        left: auto;
        top: -4px;
    }

    .education-date:before {
        display: none;
    }

    .education-date:after {
        content: "";
        top: 0;
        left: 100%;
        width: 0;
        height: 0;
        position: absolute;
        border-top: 16px solid transparent !important;
        border-bottom: 16px solid transparent !important;
        border-right: 10px solid transparent !important;
        border-left: 10px solid transparent;
    }
}

.education-logo {
    margin-bottom: 10px;
}

.education-company {
    font-size: 1em;
    margin-bottom: 15px;
}

.education-date {
    background-color: var(--main-bg-color);
    color: white; 
}

.education-date:after,
.education-date:before {
    border-color: var(--main-bg-color);
}