
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5%;
  padding: auto;

	animation: FadeIn 250ms linear;
  animation-fill-mode: both;
  background-color: #e9ecef;
}

.dot.filled {
	background-color: var(--main-bg-color);

}


.row.dot: {
  width: 100px;
  height: 100px;

}

@keyframes FadeIn {
    from { 
    	opacity: 0; }
    to   { opacity: 1; }
}

.dot:nth-child(1){ animation-delay: 0ms }
.dot:nth-child(2){ animation-delay: 10ms }
.dot:nth-child(3){ animation-delay: 200ms }
.dot:nth-child(4){ animation-delay: 300ms }
.dot:nth-child(5){ animation-delay: 400ms }
.dot:nth-child(6){ animation-delay: 210ms }
.dot:nth-child(7){ animation-delay: 240ms }
.dot:nth-child(8){ animation-delay: 265ms }
.dot:nth-child(9){ animation-delay: 290ms }
.dot:nth-child(10){ animation-delay: 310ms }