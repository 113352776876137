.center {
  text-align: center;
  padding: 5%;
  line-height: 2;
}

.shaded {
  box-shadow: -4px 7px 15px 1px rgba(0, 0, 0, 0.2);
}

.content-white {
  min-height: 500px;
}

.center-pills {
  align-content: center;
  text-align: center;
}

.card {
  padding: 2%;
  font-size: 0.8em;
  border-style: none;
  margin-top: 10px;
}

.avatar-placeholder {
  padding: 2%;
}

.card-left {
  padding: 5%;
  border-radius: 2%;
  border-color: white;
}

.btn {
  font-size: 0.9em;
}

.card-img-top {
  padding: 10%;
}


.nav-link {
  width: 3em;
  text-align: center;
  padding: .6rem .6rem;
  background-color: white;
}

.nav-item {
  width:3em;
  margin: 0em;
  padding: 0em;
}

.nav-pills .nav-link.active {
  background-color: #23272b;
}

.active {
  border-style: none;
}

a:focus  {
  outline: 0;
}