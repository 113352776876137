:root {
  --main-bg-color: #343a40;
  --secondary-color: #999;
}



.content-white {
  background-color: white; 
  padding: 5%;
  margin-top: 10px;
}

.center {
  text-align: center;
  padding: 5%;
  line-height: 2;
}

.funky-mode {
    color: #20faae;
    background-image: url("funky.jpg");
}

.funky-mode .content-white {
    background-color: black;
}

.funky-mode .navbar {
    background-color: black;
}

.funky-mode .btn  {
    background-color: purple;
    color: white;
    border-color: purple;
}

.funky-mode .nav-pills .nav-link.active {
    background-color: purple;
    color: white;
    border-color: purple;
}

.funky-mode .navbar .navbar-expand-lg .navbar-light .bg-light {
    background-color: blue;
}

.nav {
    margin-top: 10px;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 20px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 2% 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    height: 100vh;
    z-index: 999;
    background: white;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    padding: 20px;
}

#sidebar.active {
    left: 0;
}

#dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: white;
    color: black;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#dismiss:hover {
    background: #fff;
    color: #7386D5;
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.overlay.active {
    display: block;
    opacity: 1;
}

#sidebar .sidebar-header {
    padding: 20px;
    color: black;
}

#sidebar ul.components {
    margin: auto;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 20px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #f0f0f0;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.btn {
    font-family: inherit;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 8px 25px;
    display: inline-block;
    border-radius: 35px;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
    box-shadow: transparent 0 0 0;
    transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}