@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap";


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	line-height: 1.4em;
	letter-spacing: 0.08em;
	padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Quicksand", sans-serif;
}

body {
    font-family: "Quicksand", sans-serif;
}
html {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

h1 {
  font-size: 1.6em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.3em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1.1em;
}

p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

footer {
    text-align: center;
    color: var(--secondary-color);
    font-size: 0.8em;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}